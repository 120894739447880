exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ablak-tervezo-js": () => import("./../../../src/pages/ablak-tervezo.js" /* webpackChunkName: "component---src-pages-ablak-tervezo-js" */),
  "component---src-pages-bemutatkozas-js": () => import("./../../../src/pages/bemutatkozas.js" /* webpackChunkName: "component---src-pages-bemutatkozas-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kapcsolat-js": () => import("./../../../src/pages/kapcsolat.js" /* webpackChunkName: "component---src-pages-kapcsolat-js" */),
  "component---src-pages-karrier-js": () => import("./../../../src/pages/karrier.js" /* webpackChunkName: "component---src-pages-karrier-js" */),
  "component---src-pages-referenciaink-js": () => import("./../../../src/pages/referenciaink.js" /* webpackChunkName: "component---src-pages-referenciaink-js" */),
  "component---src-pages-szolgaltatasaink-js": () => import("./../../../src/pages/szolgaltatasaink.js" /* webpackChunkName: "component---src-pages-szolgaltatasaink-js" */)
}

